<template>
  <div>
    <v-data-table
      :headers="tableColumns"
      :items="items"
      :items-per-page="99"
      :show-select="false"
    >
      <template #[`item.type`]="{ item }">
        {{ item.type }}
      </template>
      <template #[`item.outcome`]="{ item }">
        {{ item.outcome }}
      </template>
      <template #[`item.user`]="{ item }">
        <span v-if="item.user_type">{{ mapType(item.user_type) }} {{ item.user_id }}</span>
      </template>
      <template #[`item.remarks`]="{ item }">
        {{ item.remarks || 'n/a' }}
      </template>
      <template #[`item.created_at`]="{ item }">
        <span>
          {{ item.created_at | dateTime }}
        </span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { dateTime } from '@core/utils/filter';
import { computed } from '@vue/composition-api';
import useOrderView from '../useOrderView';

export default {
  filters: { dateTime },

  props: {
    order: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const { orderData, loadOrder } = useOrderView()

    loadOrder(props.order.id)

    const withSysLogs = arr => {
      const { order } = props

      arr.push({
        type: 'order.created',
        created_at: order.created_at,
        outcome: 'succeeded',
      })

      return arr
    }

    const items = computed(() => withSysLogs(orderData.value ? orderData.value.logs : []))

    const tableColumns = [
      { text: 'TYPE', value: 'type', sortable: false },
      { text: 'OUTCOME', value: 'outcome', sortable: false },
      { text: 'USER', value: 'user', sortable: false },
      { text: 'REMARKS', value: 'remarks', sortable: false },
      { text: 'DATE', value: 'created_at', sortable: false },
    ]
    const mapType = type => type.split('Models')[1].replace(/\\/g, ' ')

    return {
      orderData,
      items,
      tableColumns,
      mapType,
    }
  },
}
</script>
